import React from 'react';
import { ThemeProvider } from '@mui/material';

import { theme } from 'ui';
import Layout from 'components/layout';
import 'styles/global.css';

const App = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Layout>{children}</Layout>
  </ThemeProvider>
);

export default App;
