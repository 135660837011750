exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-contentful-artist-slug-js": () => import("./../../../src/pages/artists/{contentfulArtist.slug}.js" /* webpackChunkName: "component---src-pages-artists-contentful-artist-slug-js" */),
  "component---src-pages-artists-index-js": () => import("./../../../src/pages/artists/index.js" /* webpackChunkName: "component---src-pages-artists-index-js" */),
  "component---src-pages-collections-contentful-collection-slug-js": () => import("./../../../src/pages/collections/{contentfulCollection.slug}.js" /* webpackChunkName: "component---src-pages-collections-contentful-collection-slug-js" */),
  "component---src-pages-collections-index-js": () => import("./../../../src/pages/collections/index.js" /* webpackChunkName: "component---src-pages-collections-index-js" */),
  "component---src-pages-exhibitions-contentful-exhibition-slug-js": () => import("./../../../src/pages/exhibitions/{contentfulExhibition.slug}.js" /* webpackChunkName: "component---src-pages-exhibitions-contentful-exhibition-slug-js" */),
  "component---src-pages-exhibitions-index-js": () => import("./../../../src/pages/exhibitions/index.js" /* webpackChunkName: "component---src-pages-exhibitions-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

