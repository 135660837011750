import hero1Img from 'images/hero/01.webp';
import hero2Img from 'images/hero/02.webp';
import hero3Img from 'images/hero/03.webp';
import hero4Img from 'images/hero/04.webp';
import hero5Img from 'images/hero/05.webp';
import hero6Img from 'images/hero/06.webp';
import hero7Img from 'images/hero/07.webp';
import hero8Img from 'images/hero/08.webp';

export const heroImages = [
  hero1Img,
  hero2Img,
  hero3Img,
  hero4Img,
  hero5Img,
  hero6Img,
  hero7Img,
  hero8Img,
];

export const navigationLinks = [
  {
    text: 'artists',
    href: '/artists',
  },
  {
    text: 'exhibitions',
    href: '/exhibitions',
  },
  {
    text: 'collections',
    href: '/collections',
  },
  {
    text: 'about',
    href: '/about',
  },
];

export const contact = {
  email: 'sarjanartgallery98@gmail.com',
  phone: '0265-2338351',
  address: {
    googleMapUrl: 'https://maps.app.goo.gl/VYVKsvJsV3UFcRSTA',
  },
  socials: {
    facebook: 'https://www.facebook.com/sarjanartgallery/',
    instagram: 'https://www.instagram.com/sarjanartgallery/',
    linkedin: 'https://in.linkedin.com/company/sarjan-art-gallery---india',
  },
};
