import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      light: '#efe0a4',
      main: '#a42d13',
    },
    secondary: {
      main: '#eee7c7',
    },
  },
  typography: {
    fontFamily: `'Manrope', sans-serif`,

    h1: {
      fontFamily: `'Cormorant Garamond', serif`,
      fontWeight: 700,
      fontSize: 'calc(4rem + 1vw)',
    },

    h2: {
      fontFamily: `'Cormorant Garamond', serif`,
    },

    body1: {
      lineHeight: 1.8,
    },
  },

  components: {
    MuiDivider: {
      defaultProps: {
        sx: {
          backgroundColor: '#bebebe',
        },
      },
    },
  },

  borderRadius: {
    tiny: '.8rem',
    medium: '2rem',
  },
});

export default theme;
