import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Divider, Link, Stack, Typography, styled } from '@mui/material';

import { Grid, Icon, Section } from 'ui';
import logoImg from 'images/logo.webp';
import { contact, navigationLinks } from 'utils/data';
import { developerUrl } from 'utils/app-constants';

const Footer = () => (
  <Section
    component='footer'
    style={{ paddingTop: '2.2rem', marginTop: '5rem' }}
    sx={{ py: 0, backgroundColor: 'primary.light' }}>
    <FooterPartition>
      <SocialMediaContainer>
        <FooterLogo src={logoImg} alt='LOGO' />

        <Stack gap={1} alignItems={{ xs: 'center', md: 'flex-start' }} color='primary.main'>
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
            component='a'
            href={contact.address.googleMapUrl}
            target='_blank'>
            <Icon name='MapPin' color='primary.main' />
            <Typography>Vadodara, Gujarat</Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
            component='a'
            href={`tel:${contact.phone}`}>
            <Icon name='Phone' color='primary.main' />
            <Typography>{contact.phone}</Typography>
          </Stack>
        </Stack>

        <Stack direction='row' gap={2}>
          <SocalIcon target='_blank' href={`mailto:${contact.email}`}>
            <Icon color='primary' name='Mail' />
          </SocalIcon>
          <SocalIcon target='_blank' href={contact.socials.instagram}>
            <Icon color='primary' name='Instagram' />
          </SocalIcon>
          <SocalIcon target='_blank' href={contact.socials.facebook}>
            <Icon color='primary' name='Facebook' />
          </SocalIcon>
          <SocalIcon target='_blank' href={contact.socials.linkedin}>
            <Icon color='primary' name='Linkedin' />
          </SocalIcon>
        </Stack>
      </SocialMediaContainer>

      <Grid
        columns={2}
        color='primary.main'
        justifyItems='center'
        textTransform='capitalize'
        gap={10}
        responsive={false}
        sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
        <Stack gap={2}>
          <Typography variant='h6' color='grey.800' fontWeight={600}>
            Links
          </Typography>

          {navigationLinks.map((navLink, idx) => (
            <GatsbyLink key={idx} to={navLink.href}>
              {navLink.text}
            </GatsbyLink>
          ))}
        </Stack>

        <Stack gap={2}>
          <Typography variant='h6' color='grey.800' fontWeight={600}>
            help
          </Typography>

          <a href='/terms.pdf'>Terms</a>
          <GatsbyLink to='/privacy-policy'>privacy policy</GatsbyLink>
          <a href='/quality-policy.pdf' target='_blank'>
            Image policy
          </a>
          <a href='/sitemap-index.xml' target='_blank'>
            Sitemap
          </a>
        </Stack>
      </Grid>
    </FooterPartition>

    <Divider />

    <FootNote>
      <Typography width={{ xs: '100%', md: '50%', fontSize: '.7rem' }}>
        &copy; {new Date().getFullYear()} Copyright. All rights reserved.
      </Typography>

      <p>
        Developed by{' '}
        <Link target='_blank' color='inherit' href={developerUrl}>
          Studio TikTok
        </Link>
        .
      </p>
    </FootNote>
  </Section>
);

export default Footer;

const FooterPartition = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(4),
  gap: theme.spacing(7),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const SocialMediaContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

const FooterLogo = styled('img')({
  height: '3rem',
});

const SocalIcon = styled('a')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '.5rem',
  borderRadius: '100%',
  transition: 'transform .3s',
  willChange: 'transform',

  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const FootNote = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 0),
  gap: theme.spacing(1),
  color: theme.palette.primary.main,
  fontSize: '.7rem',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));
