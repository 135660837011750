import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { Icon } from 'ui';
import { navigationLinks } from 'utils/data';
import logoImg from 'images/logo.webp';

const Navigation = () => {
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const toggleMobileNav = visibility => () => setOpenMobileNav(visibility);

  return (
    <>
      <NavBar>
        <NavList>
          {navigationLinks.map((navLink, idx) => (
            <NavListItem key={idx}>
              <Link to={navLink.href}>{navLink.text}</Link>
            </NavListItem>
          ))}
        </NavList>

        <Logo to='/'>
          <img src={logoImg} alt='LOGO' />
        </Logo>

        <Stack direction='row' alignItems='center' gap={1} justifySelf='end'>
          <Typography
            variant='subtitle2'
            fontSize='.8rem'
            fontWeight={800}
            mt={0.2}
            display={{ xs: 'none', md: 'block' }}>
            menu
          </Typography>
          <IconButton onClick={toggleMobileNav(!openMobileNav)}>
            <Icon name={!openMobileNav ? 'Menu' : 'X'} color='common.black' />
          </IconButton>
        </Stack>
      </NavBar>

      <Drawer open={openMobileNav} anchor='right' onClose={toggleMobileNav(false)}>
        <MobileNav>
          <List>
            {navigationLinks.map((navLink, idx) => (
              <MobileNavItem key={idx}>
                <Link
                  to={navLink.href}
                  target={navLink.target || '_self'}
                  activeClassName='nav-link-active'
                  onClick={toggleMobileNav(false)}>
                  {navLink.text}
                </Link>
              </MobileNavItem>
            ))}
          </List>
        </MobileNav>
      </Drawer>
    </>
  );
};

export default Navigation;

const NavBar = styled(AppBar)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3,1fr)',
  position: 'sticky',

  top: 0,
  zIndex: 99999,
  alignItems: 'center',
  padding: theme.spacing(2, 7),

  backdropFilter: 'blur(16px) saturate(180%)',
  boxShadow: 'none',
  backgroundColor: 'rgba(240, 240, 240, 0.75)',
  color: theme.palette.common.black,
  textTransform: 'uppercase',
  fontSize: '.8rem',
  fontWeight: 800,

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
  },
}));

const NavList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  display: 'flex',
  gap: theme.spacing(5),

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavListItem = styled('li')(({ theme }) => ({
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.common.black,
    left: 0,
    bottom: '-2px',
    transform: 'scaleX(0)',
    willChange: 'transform',
    transition: 'transform .3s',
    transformOrigin: 'left',
  },

  '&:hover::before': {
    transform: 'scaleX(1)',
  },
}));

const Logo = styled(Link)({
  display: 'flex',
  justifySelf: 'center',

  '& > img': {
    height: '3rem',
  },
});

const MobileNav = styled(Box)(({ theme }) => ({
  padding: theme.spacing(12, 0, 2),
  width: '20rem',
  height: '100%',
  backgroundColor: theme.palette.grey[100],
  zIndex: '9999999999',

  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(7),
    width: '100vw',
  },
}));

const MobileNavItem = styled(ListItem)({
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'capitalize',
});
