import React from 'react';
import { Typography, styled } from '@mui/material';

const Heading = ({ variant = 'tertiary', children, ...rest }) => {
  switch (variant) {
    case 'secondary':
      return (
        <HeadingSecondary variant='h2' {...rest}>
          {children}
        </HeadingSecondary>
      );

    case 'tertiary':
    default:
      return (
        <HeadingTertiary variant='h3' {...rest}>
          {children}
        </HeadingTertiary>
      );
  }
};

export default Heading;

const HeadingSecondary = styled(Typography)(({ theme }) => ({
  fontSize: '5rem',
  fontWeight: 500,
  color: theme.palette.primary.main,

  [theme.breakpoints.down('md')]: {
    fontSize: '3.5rem',
  },
}));

const HeadingTertiary = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontFamily: `'Cormorant Garamond', serif`,
  fontWeight: 700,
  color: theme.palette.primary.main,

  '&:first-letter': {
    textTransform: 'capitalize',
  },
}));
