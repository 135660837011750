import { styled } from '@mui/material';

const YoutubeVideo = styled('iframe')(({ theme }) => ({
  borderRadius: theme.borderRadius.tiny,
  width: '56rem',
  height: '31.5rem',
  border: 0,

  [theme.breakpoints.down('md')]: {
    width: '80vw',
  },
}));

export default YoutubeVideo;
