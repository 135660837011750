import React from 'react';
import { Button, styled } from '@mui/material';

const ButtonWrapper = ({ variant = 'primary', children, ...rest }) => {
  switch (variant) {
    case 'primary':
      return (
        <Button variant='outlined' {...rest}>
          {children}
        </Button>
      );

    case 'secondary':
      return (
        <ButtonSecondary variant='outlined' {...rest}>
          {children}
        </ButtonSecondary>
      );

    default:
      return <Button {...rest}>{children}</Button>;
  }
};

export default ButtonWrapper;

const ButtonSecondary = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,

  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));
