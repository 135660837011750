import React, { forwardRef } from 'react';
import { Container, styled } from '@mui/material';

const Section = forwardRef(({ children, id = null, sx = null, ...rest }, ref) => (
  <StyledSection id={id} ref={ref} sx={sx}>
    <Container {...rest}>{children}</Container>
  </StyledSection>
));

export default Section;

const StyledSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(8, 0),

  '&:first-of-type': {
    paddingTop: theme.spacing(3),
  },

  '& > .MuiContainer-root': {
    maxWidth: '90vw',
  },

  '& + section': {
    paddingTop: 0,
  },

  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(4),
  },
}));
